import Swiper from 'swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';

export default class Modal {
  constructor(element) {
    this.element = element;
    this.modalId = this.element.dataset.modalId;
    this.imageUrl = this.element.dataset.modalImage;
    this.carouselElement = this.element.closest('.swiper');
    this.clickedIndex = this.getCurrentSlideIndex();
    this.scrollbarWidth = this.getScrollbarWidth();
    this.escapeListener = null;
    this.init();
  }
  
  init() {
    this.element.addEventListener('click', this.open.bind(this));
    this.close = this.close.bind(this);
  }
  
  getCurrentSlideIndex() {
    const slides = this.carouselElement.querySelectorAll('.swiper-slide');
    let currentIndex = 0;
    slides.forEach((slide, index) => {
      if (slide === this.element) {
        currentIndex = index;
      }
    });
    return currentIndex;
  }
  updateContent() {
    if (this.modalId == 'tpl-modal-image') {
      const swiperContainer = document.createElement('div');
      swiperContainer.className = 'swiper-container';
      const swiperWrapper = document.createElement('div');
      swiperWrapper.className = 'swiper-wrapper';
  
      const slides = this.carouselElement.querySelectorAll('.swiper-slide');
      slides.forEach((slide) => {
        const newSlide = document.createElement('div');
        newSlide.className = 'swiper-slide';
        const img = document.createElement('img');
        img.src = slide.querySelector('img').src;
        img.alt = slide.querySelector('img').alt;
        newSlide.appendChild(img);
        swiperWrapper.appendChild(newSlide);
      });
  
      swiperContainer.appendChild(swiperWrapper);
  
      // Add pagination
      const pagination = document.createElement('div');
      pagination.className = 'swiper-pagination';
      swiperContainer.appendChild(pagination);
  
      this.modalElement.querySelector('.modal__content').appendChild(swiperContainer);
  
      const swiperOptions = {
        modules: [Navigation, Pagination, Keyboard],
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        initialSlide: this.clickedIndex,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        allowTouchMove: true,
        touchRatio: 1,
        grabCursor: true,
        effect: 'fade', // transition en fondu
        fadeEffect: {
          crossFade: true // transition en fondu entre les slides
        },
        speed: 300, // transition Vitesse
        breakpoints: {
          480: {
            navigation: {
              enabled: true,
            },
          },
        },
      };
  
      this.swiper = new Swiper(swiperContainer, swiperOptions);
  
      window.addEventListener('resize', () => {
        this.swiper.update();
      });
    }
  }
  handleKeyboardNavigation = (event) => {
    if (this.swiper) {
      if (event.key === 'ArrowLeft') {
        this.swiper.slidePrev();
      } else if (event.key === 'ArrowRight') {
        this.swiper.slideNext();
      }
    }
  }

  getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  }

  open(event) {
    event.preventDefault();
    const scrollbarWidth = this.scrollbarWidth;
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.classList.add('no-scroll');
    this.appendModal();
    setTimeout(() => {
      this.modalElement.classList.add('is-active');
    }, 10);
  }

  close(event) {
    this.modalElement.classList.remove('is-active');
    setTimeout(() => {
      this.modalElement.remove();
      document.body.classList.remove('no-scroll');
      document.body.style.paddingRight = '';
      document.documentElement.style.removeProperty('--scrollbar-width');

      document.removeEventListener('keydown', this.keyboardListener);
    }, 300);
  }


  appendModal() {
    const template = document.querySelector(`#${this.modalId}`);
    if (template) {
      this.modalElement = template.content.firstElementChild.cloneNode(true);
      this.updateContent();
      document.body.appendChild(this.modalElement);
      this.closeButton = this.modalElement.querySelector('.js-close');
      this.closeButton.addEventListener('click', this.close);
      
      // Fermer en cliquant à l'extérieur de la boîte
      this.modalElement.addEventListener('click', (e) => {
        if (e.target === this.modalElement) {
          this.close();
        }
      });
      
      // Gérer les événements du clavier
      this.keyboardListener = (event) => {
        if (event.key === 'Escape') {
          this.close();
        } else {
          this.handleKeyboardNavigation(event);
        }
      };
      document.addEventListener('keydown', this.keyboardListener);
    } else {
      console.log(`La <template> avec le id ${this.modalId} n'existe pas`);
    }
  }
}
