export default class Form {
    constructor(element) {
        this.element = element;
        this.init();
    }

    init() {
        this.element.addEventListener('submit', this.handleSubmit.bind(this));
    }

    handleSubmit(event) {
        event.preventDefault();
        const formData = new FormData(this.element);

        fetch(this.element.action, {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                this.showConfirmation();
            } else {
                alert(data.message || 'Une erreur est survenue. Veuillez réessayer.');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            alert('Une erreur est survenue. Veuillez réessayer.');
        });
    }

    showConfirmation() {
        const formBody = this.element.querySelector('.form__body');
        const confirmation = this.element.querySelector('.form__confirmation');
        if (formBody && confirmation) {
            formBody.style.display = 'none';
            confirmation.style.display = 'block';
        }
    }
}