export default class CardFlip {
    constructor(element) {
        this.element = element;
        this.cardInner = this.element.querySelector('.card__inner');
        this.isDesktop = window.matchMedia('(min-width: 1024px)').matches;
        this.init();
    }

    init() {
        if (this.isDesktop) {
            this.element.addEventListener('mouseenter', this.onCardHover.bind(this));
            this.element.addEventListener('mouseleave', this.onCardLeave.bind(this));
        }
        this.element.addEventListener('click', this.onCardClick.bind(this));
    }

    onCardHover() {
        if (this.isDesktop) {
            this.flipCard(true);
        }
    }

    onCardLeave() {
        if (this.isDesktop) {
            this.flipCard(false);
        }
    }

    onCardClick() {
        this.flipCard();
    }

    flipCard(flip) {
        if (flip === undefined) {
            this.cardInner.style.transform = 
                this.cardInner.style.transform === 'rotateY(180deg)' 
                    ? 'rotateY(0deg)' 
                    : 'rotateY(180deg)';
        } else {
            this.cardInner.style.transform = flip ? 'rotateY(180deg)' : 'rotateY(0deg)';
        }
    }
}
