export default class CookieConsent {
    constructor(element) {
      this.element = element;
      this.cookieName = 'cookie-consent';
      this.cookieExpiration = 365; // jours
      this.init();
    }
  
    init() {
      if (!this.getCookie(this.cookieName)) {
        this.showBanner();
      }
    }
  
    showBanner() {
      const template = `
        <div class="cookie-banner">
          <div class="cookie-content">
            <p>Ce site utilise des cookies pour améliorer votre expérience. En vertu de la loi 25 du Québec, nous devons obtenir votre consentement.</p>
            <div class="cookie-buttons">
              <button class="cookie-accept">Accepter tous les cookies</button>
              <button class="cookie-settings">Personnaliser</button>
              <button class="cookie-reject">Refuser</button>
            </div>
          </div>
        </div>
      `;
  
      this.element.innerHTML = template;
      this.addEventListeners();
    }
  
    addEventListeners() {
      const acceptBtn = this.element.querySelector('.cookie-accept');
      const settingsBtn = this.element.querySelector('.cookie-settings');
      const rejectBtn = this.element.querySelector('.cookie-reject');
  
      acceptBtn.addEventListener('click', () => this.handleConsent('accept'));
      settingsBtn.addEventListener('click', () => this.showSettings());
      rejectBtn.addEventListener('click', () => this.handleConsent('reject'));
    }
  
    showSettings() {
      const template = `
        <div class="cookie-settings-modal">
          <h2>Paramètres des cookies</h2>
          <div class="cookie-options">
            <div class="cookie-option">
              <input type="checkbox" id="essential" checked disabled>
              <label for="essential">Cookies essentiels (obligatoires)</label>
            </div>
            <div class="cookie-option">
              <input type="checkbox" id="analytics">
              <label for="analytics">Cookies analytiques</label>
            </div>
            <div class="cookie-option">
              <input type="checkbox" id="marketing">
              <label for="marketing">Cookies marketing</label>
            </div>
          </div>
          <button class="save-preferences">Sauvegarder mes préférences</button>
        </div>
      `;
  
      this.element.innerHTML = template;
      this.element.querySelector('.save-preferences').addEventListener('click', () => {
        const preferences = {
          analytics: document.getElementById('analytics').checked,
          marketing: document.getElementById('marketing').checked
        };
        this.handleConsent('custom', preferences);
      });
    }
  
    handleConsent(type, preferences = {}) {
      let consentData = {
        type: type,
        date: new Date().toISOString(),
        preferences: preferences
      };
  
      this.setCookie(this.cookieName, JSON.stringify(consentData), this.cookieExpiration);
      this.element.innerHTML = ''; // Cache la bannière
      
      // Déclencher un événement personnalisé pour informer l'application
      const event = new CustomEvent('cookieConsentUpdate', { detail: consentData });
      document.dispatchEvent(event);
    }
  
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = `expires=${date.toUTCString()}`;
      document.cookie = `${name}=${value};${expires};path=/;SameSite=Strict`;
    }
  
    getCookie(name) {
      const cookies = document.cookie.split(';');
      for (let cookie of cookies) {
        const [cookieName, cookieValue] = cookie.split('=').map(c => c.trim());
        if (cookieName === name) return cookieValue;
      }
      return null;
    }
  }