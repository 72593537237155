import ScrollReveal from 'scrollreveal';

export default class RevealAnimation {
    constructor() {
        this.sr = ScrollReveal({
            origin: 'top',
            distance: '60px',
            duration: 3000,
            delay: 400,
            reset: false // Animations repeat
        });
        this.revealElements();
    }

    revealElements() {
        this.sr.reveal(`.subtitle`, { interval: 300, reset: false});
        this.sr.reveal(`.nav-primary__item`, { interval: 200, reset: false});
        this.sr.reveal(`.footer__socials`, {delay: 600, distance: '100px', interval: 200, reset: false})
    }

    resetMenuItems() {
        const menuItems = document.querySelectorAll('.nav-primary__item');
        menuItems.forEach(item => {
            item.style.visibility = 'hidden';
            item.style.transform = 'translateY(-30px)'; 
            item.style.transition = 'none'; 
        });
    }

    revealMenuItems() {
        const menuItems = document.querySelectorAll('.nav-is-active .nav-primary__item');
        menuItems.forEach((item, index) => {
            setTimeout(() => {
                item.style.visibility = 'visible';
                item.style.transform = 'translateY(0)';
                item.style.transition = `opacity 0.4s ease ${index * 150}ms, transform 3s ease ${index * 150}ms`;
            }, 400 + index * 150); 
        });
    }
}

