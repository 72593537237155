import { LottieInteractive } from 'lottie-interactive';

export default class Lottie {
    constructor(element) {
        this.element = element;
        this.items = [];
        this.isMobile = window.matchMedia("(max-width: 768px)").matches;
        this.init();
        this.addResizeListener();
    }

    init() {
        const items = this.element.querySelectorAll('[data-lottie]');
        items.forEach(item => {
            const animationPath = item.getAttribute('data-lottie');
            const lottieElement = new LottieInteractive({
                path: animationPath,
                interaction: this.isMobile ? 'click' : 'hover',
                autoplay: false,
                loop: true
            });
            item.appendChild(lottieElement);
            this.items.push({ element: item, lottie: lottieElement });
        });
    }

    addResizeListener() {
        window.addEventListener('resize', () => {
            const wasMobile = this.isMobile;
            this.isMobile = window.matchMedia("(max-width: 768px)").matches;

            if (wasMobile !== this.isMobile) {
                this.updateInteractions();
            }
        });
    }

    updateInteractions() {
        this.items.forEach(({ lottie }) => {
            lottie.interaction = this.isMobile ? 'click' : 'hover';
        });
    }
}
  